<template>
<div class="container-fluid">
      <div class="h3 text-center font-weight-bold title">REGISTRO DE CANDIDATOS</div>
</div>
<div class="clearfix"><hr/></div>
<div class="row">
    <div class="col-md-12">
            <div class="alert alert-warning" role="alert">
            Hola {{candidate.name}} {{candidate.surname}}, te hemos enviado un correo electronico a {{candidate.email}}, para que valides tu cuenta.
            Igualmeente puedes continuar introduciendo tu información. Pero recuerda en menos de 24 hrs. debes validar tu cuenta para acceder a todas nuestras
            ofertas de empleo.
            </div>        
    </div>
</div>
<div class="row">
    <div class="col-lg-5 col-md-12">
        <div class="container-fluid mt-sm-4 mb-5">
            <div class="wrapper d-flex justify-content-center flex-column px-md-5 px-1">
                <div class="h3 text-center font-weight-bold title">Datos Personales</div>
                <div class="row my-4">
                    <div class="col-md-6"> <label>Nombre</label> <input type="text" placeholder="Nombre" v-model="candidate.firstName" readonly> </div>
                    <div class="col-md-6 pt-md-0 pt-4"> <label>Apellido</label> <input type="text" placeholder="Apellido" v-model="candidate.lastName" readonly> </div>
                </div>
                <div class="row my-md-4 my-2">
                    <div class="col-md-6"> <label>Correo electronico</label> <input type="email" placeholder="Correo electronico" v-model="candidate.email" readonly> </div>
                    <div class="col-md-6 pt-md-0 pt-4"> <label>Telefono</label> <input type="tel" placeholder="Telefono" v-model="candidate.phone" readonly> </div>
                </div>
                <div class="row my-md-4 my-2">
                    <div class="col-md-6"> <label>Fecha de Nacimiento</label> <input type="text" placeholder="DD" style="width:15%;" v-model="candidate.birthDate.day">| <input type="text" placeholder="MM" style="width:15%;" v-model="candidate.birthDate.month">|<input type="text" placeholder="YYYY" style="width:25%;" v-model="candidate.birthDate.year" readonly>  </div>
                    <div class="col-md-6 pt-md-0 pt-4"> <label>Telefono</label> <input type="tel" placeholder="Telefono" readonly> </div>
                </div>
                 <div  class="row my-md-4 my-2">
                     <div class="col-md-6 pt-md-0 pt-4">
                        <label class="mt-md-0 mt-2">Sexo</label>
                            <div class="d-lg-flex justify-content-between">
                                    <div class="size"> <label class="option"> <input type="radio" name="radio" v-model="candidate.gender" value="1">Hombre <span class="checkmark" ></span> </label> </div>
                                    <div class="size"> <label class="option"> <input type="radio" name="radio" v-model="candidate.gender" value="0">Mujer <span class="checkmark" ></span> </label> </div>                          
                            </div>                         
                     </div>
                    <div class="col-md-6 pt-md-0 pt-4"> <label>Código Postal</label> <input type="text" placeholder="Código Postal" v-model="candidate.postalCode" readonly> </div>
                 </div>          
                <div class="row my-md-4 my-2">                                       
                    <div class="col-md-6"> <label>Localidad</label> <input type="text" placeholder="Localidad" v-model="candidate.locality" readonly> </div>
                    <div class="col-md-6 pt-md-0 pt-4"> <label>Provincia</label>   <option v-for="prov in provinces" :value="prov.name" :key="prov.id">{{prov.name}}</option>
                    </div>                    
                </div>                
                <div class="row my-md-4 my-2">                    

                </div>                        
            </div>
        </div>
        <div class="container-fluid mt-sm-4 mb-5">
                <div class="wrapper d-flex justify-content-center flex-column px-md-5 px-1">
                    <div class="h3 text-center font-weight-bold title">Datos de Redes Sociales</div>
                    <div class="row my-md-4 my-2">
                        <div class="col-md-6"> <label>Perfil de Facebook</label> <input type="text" placeholder="Perfil de facebook" v-model="linkFacebook"/> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Perfil de Linkedin</label> <input type="text" placeholder="Perfil de linkedin" v-model="linkLinkedin"/> </div>
                    </div>    
                    <div class="row my-md-4 my-2">
                        <div class="col-md-6"> <label>Perfil de Instagram</label> <input type="text" placeholder="Perfil de Instagram" v-model="linkInstagram"/> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Perfil de Google</label> <input type="text" placeholder="Perfil de google" v-model="linkGoogle"/> </div>
                    </div>
                    <div class="row my-md-4 my-2">
                        <div class="col-md-6"> <label>Perfil de Skype</label> <input type="text" placeholder="Perfil de skype" v-model="linkSkype"/> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Perfil de Twitter</label> <input type="text" placeholder="Perfil de twitter" v-model="linkTwitter" /> </div>
                    </div>
                    <div class="row my-md-4 my-2">
                        <div class="col-md-6"> <label>Perfil de Youtube</label> <input type="text" placeholder="Perfil de youtube" v-model="linkYoutube" /> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Sitio Personal</label> <input type="text" placeholder="Sitio Personal" v-model="linkPersonal"/> </div>
                    </div>                                  
                </div>
            </div>          
    </div>
   
    <div class="col-lg-7 col-md-12">
        <div class="row">
                <div class="col-lg-8 col-md-12 " >
                    <div class="d-flex flex-row px-md-12" style="text-align:left;">               
                            <div class="h3 font-weight-bold title col-lg-8">Experiencia</div>
                            <div class="h3 font-weight-bold title col-lg-4" style=" text-align:right;">                                                                   
                                <el-button type="warning" plain @click="laboralVisible = true">Añadir Experiencia</el-button>
                            </div>                    
                    </div>     
                    <hr/>     
                    <LaboralExperience/>              
                </div>
                <br/><br/>
                <div class="col-lg-8 col-md-12">
                    <div class="d-flex  flex-row px-md-12"  style="text-align:left;">
                        <div class="h3  font-weight-bold title col-lg-8">Estudios</div>
                        <div class="h3 font-weight-bold title col-lg-4" style=" text-align:right;">                                                                   
                                <el-button type="warning" plain  @click="estudiesVisible = true">Añadir Estudios</el-button>
                        </div>                                   
                    </div>
                    <hr/>                       
                    <EducationExperience/>
                </div>
                <br/><br/>
                <div class="col-lg-8 col-md-12">
                    <div class="d-flex flex-row px-md-12"  style="text-align:left;">
                        <div class="h3 font-weight-bold title col-lg-8">Idiomas</div>
                        <div class="h3 font-weight-bold title col-lg-4" style=" text-align:right;">                                                                   
                                <el-button type="warning" plain  @click="languajeVisible = true">Añadir Idiomas</el-button>
                        </div>    
                    </div>
                    <hr/>                       
                    <LanguageExperience/>
                </div>    
                <br/><br/>                                    
        </div>    
    
    </div>    
</div>
<div class="row">
    <div class="col-lg-6 col-md-12">
        <el-dialog v-model="laboralVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Experiencia</h2>       
            </div>
            <div class="my-header">
                <hr/>       
            </div>              
            </template>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>
            <div class="col-lg-6 col-md-12">
                <AddLaboralExperience/>                
            </div>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>                        

        </el-dialog>        
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <el-dialog v-model="languajeVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Idiomas</h2>       
            </div>
            <div class="my-header">
                <hr/>       
            </div>              
            </template>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>
            <div class="col-lg-6 col-md-12">
                <AddLanguageExperience/>                
            </div>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>                        

        </el-dialog>        
    </div>
</div>

<div class="row">
    <div class="col-lg-6 col-md-12">
        <el-dialog v-model="estudiesVisible" >
            <template  #header >
            <div class="my-header">
                <h2  class="title" id="exampleModalLabel">Añadir Educación</h2>       
            </div>
            <div class="my-header">
                <hr/>       
            </div>              
            </template>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>
            <div class="col-lg-6 col-md-12">
                <AddEducationExperience/>                
            </div>
            <div class="col-lg-3 col-md-12">
                <br/>
            </div>                        

        </el-dialog>        
    </div>
</div>

<div class="row" style="margin-top:25px;">
  <div class="col-lg-3 col-md-12"></div>
  <div class="col-lg-4 col-md-12"><div class="d-flex justify-content-center"> <button class="btn" @click="register">Confirmar Registro</button> </div></div>
  <div class="col-lg-4 col-md-12"></div>    
</div>
<div class="row">
  <br/>
</div>


</template>

<script>
import {computed,ref} from 'vue'
import {useStore} from 'vuex'
import useCandidate from '@/modules/candidates/composables/useCandidate'
import useMasters from '@/modules/general/composables/useMasters'
import LaboralExperience from "@/modules/candidates/components/LaboralExperience.vue"
import EducationExperience from "@/modules/candidates/components/EducationExperience.vue"
import LanguageExperience from "@/modules/candidates/components/LanguageExperience.vue"
import AddLaboralExperience from "@/modules/candidates/components/AddLaboralExperience.vue"
import AddEducationExperience from "@/modules/candidates/components/AddEducationExperience.vue"
import AddLanguageExperience from "@/modules/candidates/components/AddLanguagesExperience.vue"
export default {
 components:{
     LaboralExperience,EducationExperience,AddLaboralExperience,AddEducationExperience,AddLanguageExperience,LanguageExperience
 },

 setup(){
         const store =useStore()
         const {register}=useCandidate()     
         const {getProvinces}=useMasters()
         const candidate = computed(() => store.state.candidate.candidate)
         const laboralVisible=ref(false)
         const estudiesVisible=ref(false)
         const languajeVisible=ref(false)

         getProvinces()

        return {
            candidate,
            register,
            getProvinces,
            laboralVisible,
            estudiesVisible,
            languajeVisible
        }
 },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');


.search {
    background-color: #fdfbfc
}
.title{
  color:#F07F29;
}
.search input {
    width: 100px;
    border: none;
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fdfbfc
}

div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #ac1f32d7
}

.navbar-light .navbar-nav .nav-link {
    color: #333
}

nav {
    float: left
}

#language {
    float: right
}

#language select {
    border: none;
    outline: none
}

.wrapper {
    width: 85%;
    margin: 20px auto;
    box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
}

.h3 {
    padding-top: 40px;
    font-size: 34px
}

label {
    display: block;
    font-size: 0.8rem;
    font-weight: 700
}

input {
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}

.wrapper {
    clear: both
}

#province {
    border: none;
    outline: none;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 2px solid #ddd
}

.wrapper .col-md-6:hover label {
    color: #F07F29
}

.wrapper .col-md-6:hover input,
.wrapper .col-md-6:hover #country {
    border-color: #F07F29;
    cursor: pointer
}

.wrapper .col-md-6 input:focus {
    border-color: #F07F29
}

.option {
    position: relative;
    padding-left: 30px;
    display: block;
    cursor: pointer;
    color: #888
}

.option input {
    display: none
}

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%
}

.option input:checked~.checkmark:after {
    display: block
}

.option .checkmark:after {
    content: "\2713";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out 0s
}

.option:hover input[type="radio"]~.checkmark {
    background-color: #f4f4f4
}

.option input[type="radio"]:checked~.checkmark {
    background: #F07F29;
    color: #fff;
    transition: 300ms ease-in-out 0s
}

.option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1);
    color: #fff
}


</style>