<template>
<div class="container-fluid">
      <div class="h3 text-center font-weight-bold title">REGISTRO DE CANDIDATOS</div>
</div>
<div class="clearfix"><hr/></div>
 <form   @submit.prevent="onSubmit">
    <div class="row">    
        <div class="col-lg-6 col-md-12">
            <div class="container-fluid mt-sm-4 mb-5">
                <div class="wrapper d-flex justify-content-center flex-column px-md-5 px-1">
                    <div class="h3 text-center font-weight-bold title">Datos de Acceso</div>
                    <div class="row my-4">
                        <div class="col-md-12 pt-md-0 pt-4"> 
                            <div class="col-md-12"> <label>Correo</label> <input type="text" placeholder="Correo" v-model="candidate.email" required @change="validateUser(candidate.email)"> </div>
                        </div>                   
                    </div>
                    <div class="row my-4 my-2">
                        <div class="col-md-6"> <label>Introduzca Contraseña</label> <input type="password" placeholder="Contraseña" v-model="candidate.password" required> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Repite contraseña</label> <input type="password" placeholder="Contraseña" v-model="password" required @change="comparePass"> </div>
                    </div>  
                    <div class="row my-4 my-2" v-show="valid">                       
                        <el-alert :title="msgPass" type="error" show-icon />                                       
                    </div>      
                    <div class="row my-4 my-2" v-show="validUserName">                       
                        <el-alert :title="msgUser" type="error" show-icon />                                       
                    </div>                              
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-md-12">
            <div class="container-fluid mt-sm-4 mb-5">
                <div class="wrapper d-flex justify-content-center flex-column px-md-5 px-1">
                    <div class="h3 text-center font-weight-bold title">Datos Personales</div>
                    <div class="row my-4">
                        <div class="col-md-6"> <label>Nombre</label> <input type="text" placeholder="Nombre" v-model="candidate.firstName" required @onchange="validateUser"> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Apellido</label> <input type="text" placeholder="Apellido" v-model="candidate.lastName" required> </div>
                    </div>
                    <div class="row my-md-4 my-2">
                        <div class="col-md-12"> <label>Correo electronico</label> <input type="email" placeholder="Correo electronico" v-model="candidate.email" required> </div>                   
                    </div>
                    <div class="row my-md-4 my-2">
                        <div class="col-md-6"> <label>Fecha de Nacimiento</label> 
                            <input type="number" placeholder="DD" style="width:15%;" v-model="candidate.birthDate.day" maxlength="2" :onchange="validDay" required>| 
                            <input type="number" placeholder="MM" style="width:15%;" v-model="candidate.birthDate.month" maxlength="2" :onchange="validMonth" required>|
                            <input type="number" placeholder="YYYY" style="width:25%;" v-model="candidate.birthDate.year" maxlength="4" required> 
                            <div class="row">
                                <br/>                                
                                <div class="col-lg-12 col-md-12"><el-alert :title="msgDay" type="warning" v-show="vDay"/></div><br/>
                                <div class="col-lg-12 col-md-12"><el-alert :title="msgMonth" type="warning" v-show="vMonth"/></div>                               
                            </div>

                                                                                                           
                        </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Telefono</label> <input type="tel" placeholder="Telefono" v-model="candidate.phone"> </div>
                    </div>
                    <div  class="row my-md-4 my-2">
                        <div class="col-md-6 pt-md-0 pt-4">
                            <label class="mt-md-0 mt-2">Sexo</label>
                                <div class="d-lg-flex justify-content-between">
                                        <div class="size"> <label class="option"> <input type="radio" name="radio" v-model="candidate.gender" value="1">Hombre <span class="checkmark"></span> </label> </div>
                                        <div class="size"> <label class="option"> <input type="radio" name="radio" v-model="candidate.gender" value="0">Mujer <span class="checkmark"></span> </label> </div>                          
                                </div>                         
                        </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Código Postal</label> <input type="text" placeholder="Código Postal" v-model="candidate.postalCode"> </div>
                    </div>          
                    <div class="row my-md-4 my-2">                                       
                        <div class="col-md-6"> <label>Localidad</label> <input type="text" placeholder="Localidad" v-model="candidate.locality"> </div>
                        <div class="col-md-6 pt-md-0 pt-4"> <label>Provincia</label>                            
                            <el-select v-model="candidate.province" class="m-2" placeholder="Seleccione una Provincia" size="large" style="width:90%;border-radius: 20px;height:100px;margin-right:5px;" >
                                <el-option v-for="prov in provinces" :key="prov.id" :label="prov.name" :value="prov.id"/>
                            </el-select>                                  
                        </div>                    
                    </div>                
                    <div class="row my-md-4 my-2">                    

                    </div>                        
                </div>
            </div>           
        </div>          
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-12"></div>
        <div class="col-lg-4 col-md-12">
            <div class="d-flex justify-content-center"> 
                <el-button type="primary" plain size="large" @click="submit"  style="min-height:75px;min-width:300px;margin-bottom:5px;" :disabled="!completed">Registrarse</el-button>
            
            </div>
        </div>
        <div class="col-lg-4 col-md-12"></div>    
    </div>    
</form>

<div class="row">
  <br/>
</div>


</template>

<script>
import { ref,computed} from 'vue'
import { useRouter } from 'vue-router'
import useCandidate from '@/modules/candidates/composables/useCandidate'
import useMaster from '@/modules/general/composables/useMasters'
import useSecurity from '@/modules/security/composables/useSecurity'
export default {
 components:{
    
 },
 setup(){
        const {candidate,register}=useCandidate()
        const {validateUser,validUserName,msgUser}=useSecurity()
        const {provinces, getProvinces} =useMaster()
        const router = useRouter()
        const valid=ref(false)    
        const password=ref('')
        const msgPass=ref('')
        const msgDay=ref('')
        const msgMonth=ref('')
        const vDay=ref(false)
        const vMonth=ref(false)
        const completed=ref(true)

        getProvinces()

        if(validateUser)
           completed.value=false
        else
            compelted.value=true

        function validMonth()
        {      
            if(candidate.value.birthDate.month>0 && candidate.value.birthDate.month<13)
            {
                vMonth.value=false
                completed.value=true
            }  
            else
            {
                vMonth.value=true
                completed.value=false
                msgMonth.value="El mes debe estar entre 1 y 12"            
            
            }
                
        }

        function validDay()
        {     
            if((candidate.value.birthDate.day>0) && (candidate.value.birthDate.day<31))
            {
            
                vDay.value=false
                completed.value=true
            }  
            else        
            {
                
                vDay.value=false
                completed.value=false
                msgDay.value="Los días deben estar entre 1 y 31, dependiendo el mes"            
            
            }
                
        }

        function comparePass()
        {
            console.log(candidate.value.password) 
                console.log(password.value)
                if(candidate.value.password!=password.value)
                 {
                    msgPass.value="Las contraseñas no coinciden"
                    valid.value=true
                    completed.value=false
                    return
                 }else
                   {
                    completed.value=true
                    valid.value=false
                   }
        }
        return {
            candidate,        
            valid,
            msgPass,
            msgUser,
            password,
            password,       
            msgDay,             
            msgMonth,
            vDay,
            vMonth,
            provinces, 
            getProvinces,
            validDay,            
            validMonth,
            validUserName,
            completed,
            validateUser,
            comparePass,
            onSubmit:async()=>{            
              
                const resp=await register(candidate)
                if (resp==1)
                {
                     
                 router.push({name: 'candidate'})
                }
                else
                {
                    console.log("ERROR : Register :Se ha producido un error al crear el registro")
                }
                console.log("Despues de registrar")                               
            },
            
        }
 },
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&family=Roboto&display=swap');


.search {
    background-color: #fdfbfc
}
.title{
  color:#0476e0;
}
.search input {
    width: 100px;
    border: none;
    outline: none;
    padding-top: 2px;
    padding-bottom: 2px;
    background-color: #fdfbfc
}

div.btn,
button.btn {
    background-color: #F07F29;
    color: #eee
}

div.btn:hover,
button.btn:hover {
    background-color: #ac1f32d7
}

.navbar-light .navbar-nav .nav-link {
    color: #333
}

nav {
    float: left
}

#language {
    float: right
}

#language select {
    border: none;
    outline: none
}

.wrapper {
    width: 85%;
    margin: 20px auto;
    box-shadow: 1px 1px 30px 10px rgba(250, 250, 250, 0.8)
}

.h3 {
    padding-top: 40px;
    font-size: 34px
}

label {
    display: block;
    font-size: 0.8rem;
    font-weight: 700
}

input {
    border: none;
    outline: none;
    border-bottom: 2px solid #ddd;
    width: 100%;
    padding-bottom: 10px
}

.wrapper {
    clear: both
}

#province {
    border: none;
    outline: none;
    width: 100%;
    padding-bottom: 12px;
    border-bottom: 2px solid #ddd
}

.wrapper .col-md-6:hover label {
    color: #F07F29
}

.wrapper .col-md-6:hover input,
.wrapper .col-md-6:hover #country {
    border-color: #F07F29;
}

.wrapper .col-md-6 input:focus {
    border-color: #F07F29
}

.wrapper .col-md-12:hover label {
    color: #F07F29
}
.wrapper .col-md-12:hover label {
    color: #F07F29
}
.wrapper .col-md-12:hover input{
    border-color: #F07F29;
}

.wrapper .col-md-12 input:focus {
    border-color: #F07F29
}


.option {
    position: relative;
    padding-left: 30px;
    display: block;
    color: #888
}

.option input {
    display: none
}

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 50%
}

.option input:checked~.checkmark:after {
    display: block
}

.option .checkmark:after {
    content: "\2713";
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 200ms ease-in-out 0s
}

.option:hover input[type="radio"]~.checkmark {
    background-color: #f4f4f4
}

.option input[type="radio"]:checked~.checkmark {
    background: #F07F29;
    color: #fff;
    transition: 300ms ease-in-out 0s
}

.option input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1);
    color: #fff
}


</style>