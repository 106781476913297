import {ref} from 'vue'
import { computed } from 'vue'
import {useStore} from 'vuex'
import { useRouter } from 'vue-router'
import setescaApi from "@/modules/api/setescaApi"
import useCandidate from "@/modules/candidates/composables/useCandidate"
const useOffers = () =>{
    const store =useStore()
    const {register}=useCandidate()     
    const user=ref(store.state['candidate'])
    const offers=ref([])  
    const offer=ref(null)
    const router = useRouter()
    const loading=ref(false)
    const inscriptionOK=ref(false)
    const inscriptionError=ref(false)
    const filtered=ref({
        categorie:'',
        locality:'',
        word:'',
        contractType:[],
        salaryType:[]
    })


    function searchOffersPage(page,key)
    {
        loading.value=true
        console.log('clave '+key)
        let query=''
        query="page="+page+"&"+key
           
        setescaApi.get("/bizneo/job?"+query).then(response=>{    
            loading.value=false        
            console.log(response.data)
         //   const resp =  store.dispatch('offers/setescaAddOffers', response.data)   
         //   store.dispatch('offers/addConditions', query)   
            offers.value=response.data
           /* for(var i=0;i<10;i++)
            {
                offers.value.push(response.data[i])
            }*/
        })       
    }

    function searchOffers(key,locality,category)
    {
        loading.value=true
        console.log('clave '+key)
        let query=''

        if(key=='')
            query="province="+locality+"&category="+category+"&keyWords=null"
        else
        query="province="+locality+"&category="+category+"&keyWords="+key
        
        console.log('clave '+key)
       
        setescaApi.get("/offers/jobs?"+query).then(response=>{    
            loading.value=false        
            console.log(response.data)
            const resp =  store.dispatch('offers/setescaAddOffers', response.data)   
            store.dispatch('offers/addConditions', query)   
            offers.value=response.data
           /* for(var i=0;i<10;i++)
            {
                offers.value.push(response.data[i])
            }*/
        })
    }



    function searchOffersByFiltered()
    {
        loading.value=true       
        let query=''
         
        let contract=''
        let salary=''
        filtered.value.contractType.forEach(function(elemento){
            contract+=elemento+","
        })
        

        filtered.value.salaryType.forEach(sal =>salary+=sal+",")

        
        if(filtered.value.word=='')
            filtered.value.word=null

        if (filtered.value.province=='undefined')
            filtered.value.province=null

        if (filtered.value.categorie=='')
            filtered.value.categorie=null

        if (contract=='')
            contract=null

        if (salary=='')
            salary=null

        query="word="+filtered.value.word+"&categorie="+filtered.value.categorie+"&province="+filtered.value.province+"&contractType="+contract+"&salaryType="+salary

        
        console.log(query)

        setescaApi.get("/offers?query="+query).then(response=>{    
            loading.value=false        
            console.log(response.data)
            const resp =  store.dispatch('offers/setescaAddOffers', response.data)   
            store.dispatch('offers/getSearch', query)   
            for(var i=0;i<10;i++)
            {
                offers.value.push(response.data[i])
            }
        })
    }


    function searchOfferId(id)
    {
        setescaApi.get("offers/"+id).then(response=>{            
             
        offer.value= response.data
        console.log("OFERTA")
        console.log(offer.value)
        return offer
            
        })          
    }

    function Inscription()
    {
        var user=store.getters["security/getUser"]
        console.log(user)
        console.log(offer.value.id)
        if(user!=undefined)
         { 
            store.dispatch('candidate/assignOfferCandidate',offer.value.id)
            register()
            inscriptionOK.value=true
         }
        else
        {
            store.dispatch('offers/addOffer',offer.value.id)        
            router.push({name: 'authentication'})
        }     
    }

    return{
        offers,searchOffers,searchOffersByFiltered,searchOfferId,Inscription,offer,loading,filtered,searchOffersPage,inscriptionOK,inscriptionError
    }
}
export default useOffers