<template>
 <section style="  width: 100%;height: 15vh">
    
        <div class="container">
            <h1 class="title">Detalle de la Oferta</h1>                    
        </div>
    
    <hr style="background-color:;background-color:#0476e0;"/>
 </section>
        <!-- END SECTION HEADINGS -->
        <!-- START SECTION JOB DETAIL-->
 <section class="listing blog job details">
    <div class="container-fluid" v-show="offer!=null">
        <div class="row">
            <div class="col-lg-1 col-md-12"></div>
            <div class="col-lg-6 col-md-12 blog-pots">
                <!-- Block heading end -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="detail-wrapper">
                            <div class="detail-wrapper-body">
                                <div class="row">                                                                 
                                <div class="col-xl-3 col-lg-6" >                                    
                                    <img v-show="offer.providerId==4" src="@/assets/images/setesca_log.png" alt="" />
                                    <img v-show="offer.providerId==1" src="@/assets/images/ij-logo-default_primary.svg" alt="" />
                                    <img v-show="offer.providerId==2" src="@/assets/images/logo_tecnoempleo.svg" alt="" />
                                    <img v-show="offer.providerId==3" src="@/assets/images/LinkedIn-Blue.png" alt="" /> 
                                </div>   
                                <div class="col-xl-9 col-lg-6">                                    
                                    <h2>{{offer.title}} </h2>
                                </div>     
                                <br/><br/>                               
                                </div>
                                <div class="row">
                                <div class="col-md-12">                                                                                  
                                        <div class="san mt-33">
                                        <h3>
                                            <a href="#listing-location" class="listing-address">
                                            <i class="fa fa-map-marker pr-2 ti-location-pin mrg-r-5"></i> - {{ offer.province }}
                                        </a>                                                      
                                        </h3>                            
                                    </div> 
                                    <br/><br/>
                                </div>                                         
                                </div>

                            </div>
                        </div>
                      <!--  <div class="blog-info details overview">
                            <h2 class="titleOffer">Reseña</h2>
                            <div class="mb-3" style="text-align:left;" v-html="offer.plain_description"></div>
                        </div>!-->
                        <br/>
                        <div class="blog-info details overview">
                            <h2 class="titleOffer">Descripción</h2>
                            <div  class="mb-3" style="text-align:left;" v-html="offer.description"></div>
                        </div>                                
                    </div>
                </div>
            </div>
            <aside class="col-lg-4 col-md-12 car">
                <div class="widget">
                    <br/>                     
                    <a  v-if="offer.providerId==4" target="_blank" class="btn reservation btn-radius theme-btn mrg-top-10 m-0" style="color:white;" @click="Inscription">Inscribirse</a>
                    <a  v-else target="_blank" class="btn reservation btn-radius theme-btn mrg-top-10 m-0" style="color:white;" @click="InscriptionURL(offer.url)">Inscribirse</a>                            
                    <!-- Start: Job Summary -->
                    <div class="widget-boxed mt-5"  >
                        
                        <div class="widget-boxed-header">
                            <h3><i class="fas fa-business-time pr-3"></i>Resumen de la Oferta</h3>
                        </div>
                        <div class="widget-boxed-body">                                    
                            <div class="side-list">
                                <ul style="text-align:left;">
                                    <li class="pt-0" v-show="offer.show_salary" v-if="offer.min_salary!=null"><i class="fas fa-dollar-sign pr-3"></i>Salario<span>{{offer.salary}} </span></li>
                                        <li class="pt-0" v-else><i class="fas fa-dollar-sign pr-3"></i>Salario<span>N/A</span></li>   

                                        <li class="pt-0" v-if="offer.category!=null"><i class="fas fa-dollar-sign pr-3"></i>Categoría <span>{{offer.category}}</span></li>
                                        <li class="pt-0" v-else><i class="fas fa-dollar-sign pr-3"></i>Categoría <span>N/A</span></li>    

                                        <li class="pt-0" v-if="offer.level!=null"><i class="fas fa-dollar-sign pr-3"></i>Puesto <span>{{offer.level}}</span></li>                                            
                                        <li class="pt-0" v-else><i class="fas fa-folder pr-3"></i>Puesto<span>N/A</span></li>
                                    
                                        <!--<li class="pt-0" v-if="offer.experienceMin!=null"><i class="fas fa-briefcase pr-3"></i>Experiencia mínima <span>{{offer.experienceMin.value}}</span></li>
                                            <li class="pt-0"  v-else><i class="fas fa-briefcase pr-3"></i>Experiencia mínima <span>N/A</span></li>
                                            
                                            <li class="pt-0" v-if="offer.studiesMin!=null"><i class="fas fa-graduation-cap pr-3"></i>Requisitos mínimos<span>{{offer.studiesMin.value}}</span></li>
                                            <li class="pt-0" v-else><i class="fas fa-graduation-cap pr-3"></i>Requisitos mínimos<span>N/A</span></li> -->

                                        <li class="pt-0" v-if="offer.contract_type_translation!=null"><i class="fas fa-graduation-cap pr-3"></i>Contratación<span>{{offer.contract_type_translation}}</span></li>
                                        <li class="pt-0" v-else><i class="fas fa-graduation-cap pr-3"></i>Jornada<span>N/A</span></li>

                                        <li class="pt-0" v-if="offer.socialBenefits!=null"><i class="fas fa-graduation-cap pr-3"></i>Beneficios<span>{{offer.socialBenefits}}</span></li>
                                        <li class="pt-0" v-else><i class="fas fa-graduation-cap pr-3"></i>Beneficios<span>N/A</span></li>
                                        
                                        <li class="py-0" ><i class="fas fa-clock pr-3"></i>Fecha de Publicación <span>{{new Date(offer.startDate).toLocaleString()}}</span></li>                                          
                                </ul>
                            </div>
                        </div>
                       
                    </div>
                    <br/><br/>
                    <div class="col-lg-12 col-md-12">
                        <el-alert title="Te has inscripto correctamente a la oferta"  description="Para más información podras realizar el siguimiento dentro de tu perfil." type="success" show-icon v-show="inscriptionOK"/>
                        <br/>
                        <el-alert title="Ha existido un error en la inscripción" type="error"  description="Para más información ponte en contacto con nuestro departamento de RRHH al telefono 91-4521225" show-icon v-show="inscriptionError"/>               
                    </div>
                    <!-- End: Job Summary -->
                </div>
                
            </aside>
            <div class="col-lg-1 col-md-12"></div>
        </div>
    </div>
 </section>

</template>

<script>

import { useRouter,useRoute } from 'vue-router'
import {useStore} from 'vuex'
import useOffers from '@/modules/Offers/composables/useOffers'
export default {
    setup() {
       const router = useRouter()
       const route=useRoute()
       const store =useStore()
       const {searchOfferId,offer,Inscription,inscriptionOK,inscriptionError} =useOffers()                   
       searchOfferId(route.params.id)
       console.log("descripcion - oferta")
       console.log(offer)
       
       return{
           offer,
           Inscription,
           inscriptionOK,
           inscriptionError,
           inscriptionURL(link)
           {
               console.log("link "+ link)
               router.push({path:link})
           }
           
       }
    }
}
</script>

<style>
.title{
  color:#0476e0;
}
.titleOffer{
 color:#0476e0
}

</style>